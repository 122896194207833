.modalEdit {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    margin-top: 15vh;
    left: 0;
}

.modalEdit-dialog {
    margin: auto;
    width: 50vw;
    z-index: 900;
}
@media (max-width: 712px) {
    .modalEdit-dialog {
        width: 90vw;
    }
}

.modalEditHeader {
    background-color: #f8f9fa;
    border-bottom: 1px solid #dee2e6;
}

.modalEditTitle {
    color: #212529;
    font-size: 1.1rem;
    font-weight: bold;
}

.modalEditBody {
    display: flex;
    flex-direction: column;
    font-size: 0.95rem;
    line-height: 1.2;
    user-select: none;
    width: 100%;
    padding: 0;
}

.modalEditFooter {
    background-color: #f8f9fa;
    border-top: 1px solid #dee2e6;
}

.editOption {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
    margin: 0;
    padding: 10px 5vw 10px 5vw;
    border-bottom: 0.5px solid var(--grey);
    color: var(--grey);
}
    .editOption:hover {
        background-color: #f5f5f5;
        border-color: #f5f5f5;
        color: #212529;
        cursor: pointer;
    }

    .editOption:active {
        background-color: var(--grey);
        border-color: var(--grey);
        font-weight: bold;
    }
.editOptionText {

    margin: 0;
    padding: 0;
    font-size: 1rem;
}

.editOptionButton {
    display: flex;
    align-items: center;
    align-content: center;
    background-color: transparent;
    border-color: transparent;
    color: var(--orange);
    margin: 0;
    padding: 0.25rem 1.5rem 0.25rem 0.25rem;
    font-size: 1.7rem;
}


.cancelButton {
    background-color: #808080;
    border-color: #808080;
    color: #fff;
    margin: 0 0.5rem;
    font-size: 0.95rem;
    min-width: 80px;
}

