h3 {
    color: #202020;
    font-size: 1.1rem;
    line-height: 1.3;
    font-weight:600;
}

.modalE {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    margin-top: 10vh;
    left: 0;
    max-height: 80vh;
    overflow-y: auto;
}

.modalE-dialog {
    margin: auto;
    width: 90vw;
}

.modalE-backdrop {
    z-index: 1040;
}

.modalE-header {
    background-color: var(--grey);
    border-bottom: 0;
    padding: 1vh 5vw 1vh 3vw;
}

.modalE-title {
    color: #202020;
    font-size: 1.2rem;
    font-weight: bold;
}

.modalE-body {
    float: left;
    color: #202020;
    font-size: 0.95rem;
    line-height: 1.3;
    font-weight: 300;
    user-select: none;
    width: 100%;
    margin: 0;
    padding: 3vh 3vw;
    background-color: var(--backColor);
}

.clearfix::after {
    content: "";
    display: table;
    clear: both;
}

.modalE-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--backColor);
    border-top: 0px;
    padding: 0 0 4vh 0;
}

.confirmButtonE {
    background-color: var(--orange);
    border-color: var(--orange);
    color: #fff;
    margin: 0vh 2vw;
    font-size: 0.95rem;
    min-width: 80px;
}

    .confirmButtonE:hover {
            background-color: #F06000;
            border-color: #F06000;
            color: #fff;
     }
    .confirmButtonE:active {
        background-color: var(--orange);
        border-color: var(--orange);
    }

.imageD {
    float: left;
    width: 50%;
    margin: 0 15px 5px 0;
}
