.modalPDF {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    margin-top: 2vh;
    left: 0;
    max-height: 95vh;
}

.modalPDF-dialog {
    margin: auto;
    width: 70vw;
    height: 100%;
    --bs-modal-border-width: 0px;
    --bs-modal-width: 100%;
}

@media (max-width: 801px) {
    .modalPDF-dialog {
        width: 90vw;
    }
}

.modalPDF-body {
    margin: 0;
    padding: 0vh 0vw 0vh 2vw;
    background-color: rgba(242,233,217,0.8);
}

.modalGemini-qas {
    margin: 4vh 2vw;
    padding: 2vh 2vw;
    background-color: #fff;
    border-radius: 10px;
}
    .modalGemini-qas h2 {
        font-size: 1.2em;
        color: grey;
        text-decoration:underline;
        font-weight: 400;
        line-height: 1.8em;
    }
    .modalGemini-qas h3 {
        font-size: 1.1em;
        line-height: 1.5em;
    }


.modalPDF-header {
    background-color: var(--grey);
    border-bottom: 0;
    padding: 1vh 5vw 1vh 3vw;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.3);
    z-index: 1040;
}

.react-pdf__Document {

}

.react-pdf__Page {
    background-color: transparent !important;
}

.modalPDF-document {
    max-width: 100%;
}

.modalPDF-page {
    max-width: 100%;
    color: #202020;
    font-size: 1.2rem;
    font-weight: 300;
    line-height: 1rem;
    padding: 0;
    margin-bottom: 10px;
}
.modalPDF-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.modalPDF-title1 {
    color: white;
    font-size: 1.1rem;
    font-weight: bold;
}
.modalPDF-title2 {
    color: white;
    font-size: 1rem;
    font-weight: 400;
    margin: 0 1rem;
}
.modalPDF-title3 {
    color: white;
    font-size: 0.7rem;
    font-weight: 300;
}

.annotationLayer canvas {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.modalPDF-backdrop {
    z-index: 1040;
}


.clearfix::after {
    content: "";
    display: table;
    clear: both;
}

.modalPDF-footer {
    display: flex;
    justify-content: space-around;
    width: 100%;
    align-items: center;
    background-color: var(--backColor);
    border-top: 0px;
    padding: 2vh 5vw 2vh 5vw;
    box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.3);
}

.confirmButtonPDF {
    background-color: var(--orange);
    border-color: var(--orange);
    color: #fff;
    margin: 0vh 2vw;
    font-size: 0.95rem;
    min-width: 80px;
}

    .confirmButtonPDF:hover {
        background-color: #F06000;
        border-color: #F06000;
        color: #fff;
    }

    .confirmButtonPDF:active {
        background-color: var(--orange);
        border-color: var(--orange);
    }
.zoomButtonPDF {
    display:flex;
    align-content:space-between;
    align-items:center;
    background-color: var(--backColor);
    border-color: var(--backColor);
    color: #202020;
    margin: 0vh 2vw;
    font-size: 0.8rem;
    text-align:center;
}
    .zoomButtonPDF .sliderText:first-child {
        flex: 1 1 10%; /* Flex grow, flex shrink, flex basis */
    }
.pdfzoomInput {
    padding: 0 5px;
    user-select:none;
}

.pdfzoomInput1 {
    padding: 0 5px;
    user-select: none;
    font-size: 0.7rem;
}

/* SLIDER */
input[type='range'] {
    -webkit-appearance: none; /* Override default CSS styles */
    appearance: none;
    width: 100%; /* Full-width */
    height: 0.9px; /* Slider height */
    background: black; /* Grey background */
    outline: none; /* Remove outline */
    opacity: 0.7; /* Set transparency (cross-browser) */
    -webkit-transition: .2s; /* 0.2 seconds transition on hover */
    transition: opacity .2s;
}

    /* Mouse-over effect */
    input[type='range']:hover {
        opacity: 1; /* Fully opaque */
    }

    /* The slider handle (thumb) */
    input[type='range']::-webkit-slider-thumb {
        -webkit-appearance: none; /* Override default look */
        appearance: none;
        width: 15px; /* Width of the handle */
        height: 15px; /* Height of the handle */
        background: var(--orange); /* Green background */
        cursor: pointer; /* Cursor on hover */
        border-radius: 10px;
    }

    input[type='range']::-moz-range-thumb {
        width: 20px; /* Width of the handle */
        height: 20px; /* Height of the handle */
        background: #4CAF50; /* Green background */
        cursor: pointer; /* Cursor on hover */
    }
.sliderText {
    font-size: 1.3rem;
    color: rgba(240,110,30,0.5);
    text-align: center;
    margin: 0 4px 0.3rem 4px;
    font-weight: 200;
    cursor: pointer;
}
    .sliderText:hover {
        color: var(--orange);
    }
.disabledIcon {
    color: var(--grey);
    user-select: none;
}
    .disabledIcon:hover {
        color: var(--grey);
    }
.pdf-pageButton {
    font-size: calc(12px + 1vw);
    padding: 0;
    margin: 0 8px 8px 8px;
    color: rgba(240,110,30,0.5);
    background-color: transparent;
    border-color: transparent;
    cursor: pointer;
    transition: all 0.5s;
}

    .pdf-pageButton:hover {
        background-color: transparent;
        border-color: transparent;
        color: var(--orange);
    }

    .pdf-pageButton:active {
        background-color: transparent;
        border-color: transparent;
        color: var(--orange);
    }

    .pdf-pageButton:disabled {
        background-color: transparent;
        border-color: transparent;
        color: var(--grey);
    }

.pdf-navigation {
    font-size: calc(8px + 0.8vw);
}

.loadFileContainer {
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 4%;
    height: 20vh;
}
.pdf-pageInput {
    text-align: center;
    width: auto;
    border: 1px solid #ccc;
}
    /* For Webkit browsers like Chrome, Safari */
    .pdf-pageInput::-webkit-outer-spin-button,
    .pdf-pageInput::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* For Firefox */
    .pdf-pageInput[type=number] {
        -moz-appearance: textfield;
    }
    .pdf-pageInput:focus {
        border-color: var(--orange); /* Change border color on focus */
        outline: none; /* Optional: remove the default focus outline */
        
    }

