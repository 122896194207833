body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


/********** CONTAINERS **********/


.upPage {
    display: flex;
    flex-direction: column;
    max-width: inherit;
    margin: 0px 0 1px 0;
    padding: 0 0 2vh 0;
    background-color: #fff;
}

.upPageHalfMidL {
    width: 100%;
    margin: 0;
    padding: 2vh 8vw 2vh 8vw;
    background-color: transparent;
}
.upPageHalfMidR {
    width: 100%;
    margin: 0;
    padding: 2vh 8vw 1vh 8vw;
    background-color: transparent;
}
.upContainer {
    width: 100%;
    margin: 0;
    padding: 2vh 8vw 2vh 8vw;
    background-color: transparent;
}

.qaPage {
    margin: 0;
    padding: 0;
}

.fixImage {
    background-size: cover;
    background-position: center;
    position: relative;
    max-height: 100%;
    max-width: 100%;
    min-height: 16vh;
    z-index: -1;
}
.fixFolder {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #010200;
    min-height: 8vh;
}
.folder {
    text-align: left;
    margin-left: 30px;
    padding: 0 10px;
    font-weight: 600;
    font-size: 1.05em;
    color: var(--backColor);
}
.folder-icon {
    margin: 0 10px 4px 0;
    font-size: calc(15px + 1vmin);
}
.STitleContainer {
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    background-color: #fff;
}
.STitleContainer p {
    margin: 0;
    padding: 0;
}
.STitle1 {
    display: flex;
    align-items: center;
    font-size: calc(12px + 1vmin);
    color: #3c3c3c;
    font-weight: 400;
    height: 9vh;
}
.STitle2 {
    display: flex;
    align-items: center;
    text-align: center;
    margin: 0;
    padding: 0 4vw;
    font-size: calc(10px + 0.9vmin);
    line-height: calc(15px + 1vmin);
    color: #3c3c3c;
    font-weight: 300;
}
.STitle3 {
    display: flex;
    align-items: center;
    text-align: center;
    margin: 0;
    padding: 0 4vw;
    font-size: calc(10px + 0.9vmin);
    color: #3c3c3c;
    font-weight: 300;
    height: 9vh;
}
.STitle4 {
    margin: 0;
    padding: 0 0 2vh 0;
    text-align: center;
}
.qaTitle {
    margin: 0;
    padding: 2vh 2vw 0vh 2vw;
    background-color: #fff;
    text-align: center;
    font-size: calc(12px + 1vmin);
    color: #3c3c3c;
    font-weight: 300;
}
.qaTitle1 {
    margin: 0;
    padding: 4vh 4vw 0vh 4vw;
    text-align: center;
    font-size: calc(12px + 1vmin);
    line-height: calc(15px + 1vmin);
    color: #3c3c3c;
    font-weight: 400;
    min-height: 12vh;
}
.qaTitle2 {
    margin: 0;
    padding: 0vh 4vw 0vh 4vw;
    text-align: center;
    font-size: calc(10px + 0.9vmin);
    line-height: calc(15px + 1vmin);
    color: #3c3c3c;
    font-weight: 300;
}
.qaPageQA {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0 5vw 1vh 5vw;
    background-color: var(--backColor);
}

.qaPageR {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 100%;
    min-height: 80px;
    margin: 0 0 5vh 0;
}
.qaPageR2 {
    display: flex;
    align-content:center;
    justify-items:center;
    width: 100%;
}
.qaPageR1 {
    display: flex;
    justify-content: center;
    margin: 0 0 2vh 0;
}

.submitButton {
    width: 18vw;
    padding: 1.5vh 1vw;
    font-size: calc(9px + 1vmin);
    border: none;
    color: white;
    background-color: var(--orange);
    cursor: pointer;
    border-radius: 12px;
}
.submitButton.s {
    width: 100px;

}

    .submitButton:hover {
        border: none;
        color: white;
        font-weight: 600;
        background-color: rgba(240, 110, 30, 0.8);
    }
    .submitButton:disabled {
        background-color: var(--grey);
        pointer-events: none;
    }

.submitButton_disabled {
    background-color: var(--grey);
    pointer-events: none;
}


.submitButton1 {

    padding: 0px 15px 15px 5px;
    border: none;
    color: var(--orange);
    background-color: transparent;
    cursor: pointer;
}

    .submitButton1:hover {
        border: none;
        color: black;
    }

    .submitButton1:disabled {
        color: var(--grey);
        pointer-events: none;
    }


.blink-box {
    height: 40px;
    width: 100%;
    margin: 1vh 0 5vh 0;
    padding: 10px;
    background: linear-gradient(90deg, transparent, rgba(150, 150, 150, 0.4), transparent);
    background-size: 200%;
    animation: highlight 1s reverse linear infinite;
}
.blink-box-row {
    height: 25px;
    width: 100%;
    margin: 10px 0 0 0;
    padding: 10px;
    border: solid 3px white;
    background: linear-gradient(90deg, transparent, rgba(150, 150, 150, 0.4), transparent);
    background-size: 200%;
    animation: highlight 2s reverse infinite;
}
.blink-box-row1 {
    height: 25vh;
    width: 100%;
    margin: 0;
    padding: 10px 0 0 0;
    background: linear-gradient(90deg, #fff, var(--grey), #fff);
    background-size: 300%;
    animation: highlight 2s reverse infinite;
    border:none;
}
.blink-box-title {
    height: 100%;
    width: 60%;
    margin: 0 20vw;
    color: white;
    padding: 0;
    background: linear-gradient(90deg, transparent, rgba(150, 150, 150, 0.4), transparent);
    background-size: 150%;
    animation: highlight 1s reverse linear infinite;
    display: flex;
    justify-content: center; /* horizontal alignment */
    align-items: center; /* vertical alignment */
}
.blink-box-PDF {
    display:flex;
    align-items:center;
    justify-content:center;
    height: 80vh;
    width: 100%;
    margin: 5vh 5vw;
    padding: 10px;
    background: linear-gradient(90deg, transparent, rgba(150, 150, 150, 0.4), transparent);
    background-size: 100%;
    animation: highlight 1s reverse linear infinite;
    font-size:1em;
    color: white;
    text-align:center;
}
.projectTitle {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0vh 0 0 0;
    padding: 4vh 2vw 2vh 2vw;
    background-color: var(--backColor);
    text-align: center;
}

.AnswerText {
    max-width: 100%;
    min-height: 15%;
    font-size: calc(9px + 1vmin);
    color: #3C3C3C;
    padding: 1vh 2vw;
    font-weight: normal;
    text-align: left;
    flex-basis: 100%;
    margin-bottom: 5vh;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    word-wrap: break-word; 
    white-space: normal;
}
.QuestionContainer {
    width:100%;
    padding: 10px 20px 20px 20px;
    background-color: #fff;
    border-top-left-radius: 0px;
    border-top-right-radius:0px;
}
.QuestionText {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #fff;
    border-radius: 12px;
    border: 1px solid #ccc;
}
    .QuestionText:hover {
        box-shadow:  rgba(0,0,0,0.2) 5px 0px 10px;
    }

.editable-div {
    position: relative;
    width: 100%;
    padding: 10px;
    overflow-y: auto;
    font-size: calc(9px + 1vmin);
    color: #3C3C3C;
    font-weight: normal;
    text-align: left;
    border: none;
    border-radius: 0;
    background-color:transparent;
}

    .editable-div:focus {
        outline: none; /* Remove the default focus outline */
    }
    .editable-div.empty::before {
        content: attr(data-placeholder);
        top: 0px;
        left: 10px;
        color: #888;
        /* Hide the placeholder when the div is focused or has content */
        visibility: hidden;
    }
    .editable-div[contenteditable]:empty:not(:focus)::before {
        visibility: visible;
    }
.input-placeholder {
        color: #888;
        pointer-events: none; /* Prevent the placeholder from being selectable */    
}
.QAImage {
    max-height:100%;
    max-width:100%;
    margin:1px 0 5vh 0;
    padding:0;
}
.STable {
    padding: 0;
    flex-basis: 100%;
    margin-bottom: 0vh;
}

/*  OTHERS */

.main_titles {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: center;
    padding: 0.5em;
    font-size: calc(10px + 1vmin);
    font-weight: normal;
    color: #3C3C3C;
    margin: 0;
}
.pdfUploadTitle {
    text-align: center;
    padding: 0.5em;
    font-size: calc(12px + 1vmin);
    font-weight: 300;
    color: #3C3C3C;
    margin: 0;
}
.pdfList {
    text-align: center;
    padding: 0.5em;
    font-size: calc(8px + 1vmin);
    font-weight: 300;
    color: #3C3C3C;
    margin: 0;
}
.checkBox {
    display: flex;
    align-items: center;
    text-align: center;
    padding: 10px 8px;
    font-size: calc(9px + 1vmin);
    margin: 0px;
    color: #3c3c3c;
    border-bottom: 0px solid #ccc;
}

.disabled {
    opacity: 0.5;
    pointer-events: none;
}

.QButton {
    width: 18vw;
    padding: 2vh 1vw;
    font-size: calc(7px + 1vmin);
    margin-bottom: 0px;
    border: none;
    color: white;
    background-color: var(--orange);
    cursor: pointer;
    border-radius: 12px;
}

.QButton_disabled {
    background-color: #BBB;
    pointer-events: none;
}

.add-icon {
    font-size: calc(18px + 0.9vw);
    color: var(--grey);
    cursor: pointer;
}
    .add-icon:hover {
        color: var(--orange);
    }
.add-icon1 {
    max-width: 4vw;
    font-size: calc(10px + 1vw);
    color: var(--grey);
    cursor: pointer;
}
    .add-icon1:hover {
        color: var(--orange);
    }
.add-iconPDF {
    max-width: 4vw;
    font-size: calc(10px + 1vw);
    cursor: pointer;
    color: var(--grey);
}
    .add-iconPDF:hover {
        color: var(--orange);
    }

.addButton:hover {
    border: none;
    color: white;
    font-weight: 600;
    background-color: rgba(240, 110, 30, 0.8);
}
.add-icon1.o {
    color: var(--orange);
}
    .add-icon1.o:hover {
        color: grey;
    }
.add-icon1.s {
    max-width: 2.5vw;
    font-size: calc(9px + 0.8vw);
}
.add-icon1.d {
    cursor: auto;
}
    .add-icon1.d:hover {
        color: #3c3c3c;
    }

.add-icon1.p {
    cursor: auto;
}
@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}
.summary-icon {
    max-width: 2.5vw;
    font-size: calc(9px + 0.8vw);
    cursor: auto;
    color: var(--grey);
}

.add-ico-rotate {
    color: var(--orange);
    max-width: 2.5vw;
    font-size: calc(9px + 0.8vw);
    display: inline-block;
    transition: transform 0.3s ease;
}
    .add-ico-rotate:hover {
        animation: rotate 2s linear infinite;
    }
.DemoImage {
    display: flex;
    align-items: center;
    align-content: center;
    max-width: 100%;
    padding: 0;
    margin: 0;
    background-color: blue;
}
/****   Select Component *****/
select {
    width: 40vw;
    font-size: calc(10px + 1vmin);
    padding: 12px 8px 14px 16px;
    border: 0px solid #ccc;
    border-radius: 8px;
    background-color: #fff;
    color: #3C3C3C;
    -webkit-appearance: none;
    -moz-appearance: none;
    box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.1);
}

    select:focus {
        outline: none;
        border-color: #808080;
    }
/***********  SPINNER ***********/

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


.loading-spinner-container1 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10vh;
    background-color: transparent;
}

.loading-spinner1 {
    display: inline-block;
    width: 7vh;
    height: 7vh;
    margin: 0 1vh;
    border-radius: 50%;
    border: 8px solid #ccc;
    border-top-color: var(--orange);
    animation: spin 1s ease-in-out infinite;
}

.categoryDropdown {
    width: 26vw;
    padding: 1.1vh 2vw;
    font-size: calc(9px + 1vmin);
    margin-left: 2px;
    border: none;
    color: #3C3C3C;
    cursor: pointer;
    background-color: transparent;
    border-radius: 1px;
    border: 1px solid #ccc;
    box-shadow: none;
}
@keyframes highlight {
    0% {
        background-position: -100%;
    }

    100% {
        background-position: 100%;
    }
}


.project {
    font-size: 0.9em;
    padding: 0 3px;
    color: var(--orange);
    font-weight: bold;
}
/***********  DROPZONE ***********/
.dropzone {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    height: 20vh;
    background-color: #f2f2f2;
    text-align: center;
    padding: 0.5em;
    font-size: calc(10px + 1vmin);
    font-weight: 300;
    color: #3C3C3C;
    margin: 0;
    cursor: pointer;
    border: 1px dashed #3C3C3C;
}

.feedback-icons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: #fff;
}
.feedback-icons.bm {
    padding-bottom: 4vh;
}

.feedback-icons > * {
    cursor: pointer;
    margin: 10px 20px 0px 0;
    color: lightgray;
    transition: transform 0.2s, opacity 0.2s; /* Smooth transitions */
}
    /* Hover state */
    .feedback-icons > *:hover {
        opacity: 0.8; /* Makes the icon slightly transparent on hover */
        transform: scale(1.2); /* Slightly scales the icon on hover */
    }

    /* Active (click) state */
    .feedback-icons > *:active {
        transform: scale(0.9); /* Slightly scales down the icon on click */
        filter: brightness(0.7);
    }
.icon-History {
    background-color: transparent;
    border: none;
    padding: 0;
    margin: 0;
}
    .icon-History > * {
        cursor: pointer;
        color: lightgray;
        transition: transform 0.2s, opacity 0.2s; /* Smooth transitions */
    }
/* Hover state */
    .icon-History > *:hover {
        opacity: 1; /* Makes the icon slightly transparent on hover */
        transform: scale(1.2); /* Slightly scales the icon on hover */
    }
    .icon-History:focus {
        outline: none;
    }

.DashBoardLink {
    cursor: pointer;
    color: white;
    font-weight: 300;
    background-color: var(--orange);
    width: 100%;
    height: 22%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    margin-top: 4px;
    box-shadow:rgba(0,0,0,0.1) 4px 4px;
}
    .DashBoardLink:hover {
        background-color: rgba(240,110,30,0.9);
        font-weight: 500;
    }
.DashBoardTitle {
    color: white;
    font-weight: 600;
    background-color: var(--orange);
    width: 100%;
    height: 8vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    border: 10px solid var(--backColor);
}
.links-text {
    color: white;
    font-size: 1em;
    text-align: center;
    line-height: 2;
    text-decoration: none;
}

    .links-text:hover {
        color: white;
    }
.DashBoard-text {
    display: grid;
    grid-template-rows: repeat(3, 8vh); /* This creates 3 rows of maximum 10vh each */
    grid-template-columns: 60% 40%; /* This creates 2 columns with widths of 70% and 30% respectively */
    grid-gap: 0px; /* This adds a gap between rows and columns */
    font-size: calc(12px + 0.5vmax);
    color: #202020;
    line-height: 1.1;
    margin: 10px 0;
    padding: 15px 10px 0 10px;
    font-weight: 200;
    text-align: left;
    align-items: center;
    background-color: #FFFAF2;
    border: 4px solid var(--backColor);
}
.DashBoard-priceTable {
    display: flex;
    flex-wrap: wrap;
    flex-basis: 100%;
    align-items: center;
    align-content: center;
    justify-content: center;
    background-color: var(--backColor);
    margin: 0vh 5vw 5vh 5vw;
}
.DashBoard-global {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    background-color: #FFFAF2;
    margin: 0vh 10vw 10vh 10vw;
    padding: 1em 0vw;
    box-shadow: rgba(100,100,100,0.1) 4px 4px;
    border-radius: 10px;
    cursor: pointer;
}
.DashBoard-global:hover {
    background-color: #FFFF;
}
@keyframes Dashblink {
    0% {
        opacity: 0.2;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0.2;
    }
}
.DashBoard-bold {
    font-weight: 500;
    text-align: right;
    padding: 0 8%;
    border: 2px solid var(--backColor);
    background-color: var(--backColor);
}
.blinking-dashboard {
    display: flex;
    animation: Dashblink 1s linear infinite;
    width: 90%; /* or whatever size you want */
    height: 100px; /* or whatever size you want */
    background-color: #FCF5E8; /* choose a color */
    margin: auto;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
    font-size: calc(10px + 0.8vmax);
    color: #202020;
    line-height: 1.1;
}

.ContractTable {
    margin: 0;
    padding: 3vh 0 0 0;
    background-color: var(--backColor);
    min-height: 1000px;
}

/* tables.css */
/**************** TABLES  ********************/
/* tables.css */
table {
    width: 100%;
    border-collapse: collapse;
}

th,
td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ccc;
}

.pricingTable {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    background-color: #FCF5E8;
    color: #202020;
    padding: 0 0 2vh 0;
    font-size: calc(9px + 0.4vw);
    user-select: none;
}

    .pricingTable tbody {
        width: 100%;
        table-layout: fixed;
        border-collapse: collapse;
    }

    .pricingTable td {
        text-align: center;
        color: #3C3C3C;
        word-break: break-word;
    }

    .pricingTable th {
        font-weight: 400;
        font-size: 1.2em;
        text-align: center;
        color: #fff;
        background-color: var(--grey);
    }
    .pricingTable td input {
        width: 80%;
        box-sizing: border-box;
    }

.chat-entry {
    display: grid;
    grid-template-columns: auto 1fr; /* Icon and Text Group */
    align-items: start; /* Align items to the start */
    gap: 10px; /* Space between icon and text group */
    margin-bottom: 10px;
    max-width:100%;
}
    .chat-entry h1 {
        font-size: 1.2em;
        text-decoration: underline;
        font-weight: 600;
        line-height: 1.8em;
    }
    .chat-entry h2 {
        font-size: 1.2em;
        color: gray;
        text-decoration: none;
        font-weight: 600;
        line-height: 1.7em;
    }
    .chat-entry h3 {
        font-size: 1.1em;
        line-height: 1.5em;
    }

.chat-icon {
    justify-self: center;
    margin-top: 4px;
}

.label {
    font-weight: bold; /* Make label bold */
}

.chat-text {
    margin-top: 4px; /* Small space between label and text */
    overflow-wrap: break-word;
    word-wrap: break-word;
    width:100%;
    max-width: 100%;
}

    .chat-text pre code {
        background-color: #f5f5f5; /* Light grey background */
        padding: 10px; /* Padding inside code blocks */
        border-radius: 5px; /* Rounded corners for code blocks */
        width: 100%;
        max-width: 100%;
        display: block;
        overflow-x: auto;
        word-break: break-all;
        white-space: pre-wrap;
    }
    .chat-text.g {
        color: var(--grey);
    }
    .chat-text.geminiA {
        font-size: 0.9em;
    }
    .chat-text.geminiQ {
        font-size: 1em;
        font-weight:400;
        color: var(--orange);
        line-height: 1.2em;
    }
    .chat-text.geminiQ.s {
        font-size: 0.8em;
    }

/* Lazy Load Images*/
.lazy {
    width: 100%;
    height: auto;
}

.scroll-top {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    margin-bottom: 4vh;
}
    .scroll-top > * {
        cursor: pointer;
        margin: 10px 20px;
        color: var(--orange);
        transition: transform 0.2s, opacity 0.2s; /* Smooth transitions */
    }
    /* Hover state */
        .scroll-top > *:hover {
            opacity: 0.8; /* Makes the icon slightly transparent on hover */
            transform: scale(1.2); /* Slightly scales the icon on hover */
        }
