.square {
    z-index: 1;
    position: relative;
    width: 20%;
    padding-top: 20%; /* 1:1 Aspect Ratio */
    filter: grayscale(10%);
    background-color: rgb(32, 32, 32);
    margin: 0px;
}

.square-container {
    position: relative;
    overflow: hidden;
}


.rect-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 80%;
}
.rect-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 80%;
}
@keyframes fadeInScaleUp {
    from {
        opacity: 0;
        transform: scale(0);
    }

    to {
        opacity: 0.95;
        transform: scale(1);
    }
}

.svg-container {
    position:relative;
    padding-top: 100%;
    background-color: black;
    overflow: hidden;
    width: 100%;
}
.svg-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.qtig-app {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.qtig-container {
    position: relative;
    padding-top: 50%;
    background-color: rgb(80, 80, 80);
    overflow: hidden;
    width: 100%;
}

.qtig-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.qtig-logo {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
}

.center-text {
    position: absolute;
    z-index: 2; /* To make sure it's above the squares */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
    color: white;
    background-color: rgba(32, 32, 32, 1); /* Optional, if you want some background */
    padding: 10px;
}
