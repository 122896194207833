.modalD {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    margin-top: 15vh;
    left: 0;
}

.modalD-dialog {
    margin: auto;
    width: 50vw;
    box-shadow: 5px 20px 16px 5px rgba(0, 0, 0, 0.2);
    --bs-modal-border-width: 0px;
    --bs-modal-width: 100%;
}
@media (max-width: 801px) {
    .modalD-dialog {
        width: 80vw;
    }
}
.modalD-dialog.a {
    margin: auto;
    width: 40vw;
    margin-top: 5vh;
    --bs-modal-border-width: 1px;
    --bs-modal-width: 100%;
}

@media (max-width: 801px) {
    .modalD-dialog.a {
        width: 60vw;
    }
}

.modalD-backdrop {
    z-index: 1140;
}
.modalS {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    margin-top: 8vh;
    left: 0;
}

.modalS-dialog {
    margin: auto;
    width: 50vw;
    height:80vh;
    --bs-modal-border-width: 0px;
    --bs-modal-width: 100%;
}

@media (max-width: 801px) {
    .modalS-dialog {
        width: 90vw;
    }
}
.modalHeader {
    background-color: #f8f9fa;
    border-bottom: 1px solid #dee2e6;
}

.modalTitle {
    color: #212529;
    font-size: 1.1rem;
    font-weight: bold;
    user-select: none;
}
.modalTitleP {
    font-size: 1rem;
    font-weight: 400;
}

.modalBody {
    color: #212529;
    font-size: 0.95rem;
    line-height: 1.2;
    user-select: none;
    width: 100%;
}

.modalSummaryBody {
    color: #212529;
    padding: 30px;
    font-size: 0.90rem;
    line-height: 1.5;
    width: 100%;
}

.modalFooter {
    background-color: #f8f9fa;
    border-top: 1px solid #dee2e6;
}

.confirmButton {
    background-color: var(--orange);
    border-color: var(--orange);
    color: #fff;
    margin: 0.5rem;
    font-size: 0.95rem;
    min-width: 80px;
    transition: all 0.5s;
}

    .confirmButton:hover {
        background-color: #F06000 !important;
        border-color: #F06000 !important;
        color: #fff !important;
    }
    .confirmButton:active {
        background-color: #F06000 !important;
        border-color: #F06000 !important;
        box-shadow:   rgba(0,0,0,0.1) 2px 1px  inset !important ;
    }
    .confirmButton:disabled {
        background-color: var(--grey) !important;
        border-color: var(--grey) !important;
    }

.cancelButton {
    background-color: #808080;
    border-color: #808080;
    color: #fff;
    margin: 0 0.5rem;
    font-size: 0.95rem;
    min-width: 80px;
}
.input-modal {
    width: 100%;
    border: 1px solid #dee2e6;
    padding: 5px 10px;
}

.modalD1-dialog {
    margin: auto;
    width: 30vh;
    margin-top: 5vh;
    --bs-modal-border-width: 0px;
    --bs-modal-width: 100%;
}
