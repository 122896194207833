.AnalysisPage {
    margin: 0;
    padding: 3vh 0 10vh 0;
    background-color: var(--backColor);
}

.AnalysisContainer {
    display: flex;
    flex-wrap: wrap;
    margin: 0 20% 0px 20%;
    padding: 15px 20px 35px 20px;
    font-size: calc(13px + 0.2vh);
    user-select: none;
    border-radius: 8px;
    background-color: #ffff;
    min-height: 20vh;
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.1);
}
@media (max-width: 801px) {
    .AnalysisContainer {
        margin: 0 3% 0 3%;
    }
}
.AnalysisContent {
    width: 100%;
    background-color: #fff;
    border-radius: 0;
    border: 1px solid #e9e9e9;
    border-collapse: collapse;
}
.tab-headers {
    list-style-type: none;
    padding: 0;
    display: flex;
    border-collapse: collapse;
    margin-bottom: 0;
    max-height: 6vh;
}

    .tab-headers li {
        min-width: 20%;
        cursor: pointer;
        padding: 5px 10px;
        border: 1px solid #e9e9e9;
        border-bottom: 0; /* Remove bottom border to merge tabs with content box */
        margin-right: 2px; /* Add space between tabs */
        border-top-left-radius: 10px; /* Rounded corners on the top-left */
        border-top-right-radius: 10px; /* Rounded corners on the top-right */
    }

        /* Style for non-active tabs to look detached from the content box */
        .tab-headers li:not(.active) {
            border-bottom: 0px solid #ccc; /* Add bottom border back to non-active tabs */
            background-color: #e9e9e9; /* Lighter background for non-active tabs */
        }

        /* Active tab style */
        .tab-headers li.active {
            background-color: #fff;
            border-bottom: 0;
        }

.tab-content {
    border: 1px solid #ccc;
    padding: 5px 10px;
    border-radius: 0 0 10px 10px; /* Rounded corners at the bottom of the content box */
}

