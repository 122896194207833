@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;700&display=swap');

:root {
    --orange: #F06E1E;
    --backColor: #F2EEE0;
    --grey: #B7B6B1;
    --lightGrey: #F5F5F5;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


