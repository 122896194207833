body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/***********  Main Site general format ***********/
.Tw-Body {
    background-color: rgb(32, 32, 32);
    margin: 0;
    padding: 4vh 0 0 0;
}

.Tw-text {
    display: flex;
    flex-direction: column;
    height: 100%;
    font-size: calc(14px + 1.1vmax);
    color: var(--backColor);
    line-height: 1.1;
    margin: 0;
    padding: 20px;
    font-weight: 200;
    text-align: center;
    align-items: center;
    justify-content: center;
    user-select: none;
}
.Tw-text1 {
    font-size: calc(15px + 1.1vmax);
    font-weight: 600;
}
.Tw-text2 {
    display: flex;
    flex-direction: column;
    height: 100%;
    font-size: calc(12px + 1vmax);
    color: white;
    line-height: 1.1;
    margin: 0;
    padding: 20px;
    font-weight: 200;
    text-align: center;
    align-items: center;
    justify-content: center;
}
.Tw-text3 {
    background-color: black;
}
.Tw-text4 {
    font-size: calc(12px + 1vmax);
}
.Tw-text5 {
    font-size: calc(10px + 1vmax);
}
.Tw-textPrivacy1 {
    display: flex;
    flex-direction: column;
    height: 100%;
    font-size: calc(12px + 1vmax);
    color: white;
    line-height: 1.1;
    margin: 0;
    padding: 20px;
    font-weight: 200;
    text-align: center;
    align-items: center;
    justify-content: center;

}
.logo {
    height: calc(60px + 6vw);
    margin: 2vmin;
}
.qtiglogo {
    max-height: 52vh;
    max-width: 100vw;
}
.App-logo {
    height: 45px;
    float: left;
    pointer-events: none;
    margin: 0px 20px 0px 30px;
}
.qtigInLine {
    height: calc(25px + 1.1vmax);
    pointer-events: none;
    margin: 0;
}

.Tw-header {
    z-index: 1000;
    display: flex;
    align-items: center;
    width: 100%;
    position: sticky;
    top: 0;
    background-color: rgb(32, 32, 32);
    min-height: 8vh;
    margin: 0;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
}
.App-header {
    z-index: 1000;
    display: flex;
    align-items: center;
    width: 100%;
    position: sticky;
    top: 0;
    background: #202020;
    min-height: 9vh;
    margin: 0;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.3);
}

.TW-login {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 5vh;
    margin-right: 20px;
    font-size: 18px;
    font-weight: 300;
    color: white;
}
    .TW-login:hover {
        font-weight: 600;
        color: var(--orange);
    }
.Tw-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: var(--backColor);
    min-height: 5vh;
    margin: 0;
    display: flex;
}

.Twfooter_text {
    font-size: calc(10px + 1vmin);
    color: #3C3C3C;
    margin: 0 10px;
    padding: 1vmin;
    font-weight: normal;
    font-family: Montserrat;
    text-align: left;
    flex-basis: 100%;
}
.Tw-logo {
    height: 40px;
    float: left;
    pointer-events: none;
    margin: 1vh 0px 0px 3vh;
}
.header_text {
    font-size: calc(12px + 1vmin);
    margin: 8px 0px 0px 0px;
    color: var(--backColor);
    font-weight: normal;
    font-family: Montserrat;
    text-align: left;
    flex-basis: 100%;
}

.App-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #202020;
    min-height: 6vh;
    margin: 0;
    display: flex;
    align-items: center;
}

.footer_text {
    font-size: calc(8px + 1vmin);
    color: var(--backColor);
    margin: 0 10px;
    padding: 1vmin;
    font-weight: normal;
    font-family: Montserrat;
    text-align: left;
    flex-basis: 100%;
}
.claim {
    margin: 0;
    padding: 10px;
    background-color: #DEE1E2;
    color: black;
    font-size: calc(12px + 1vmin);
    font-weight: 200;
    user-select: none;
    text-align: center;
}
/***********  Main twintig page ***********/
.qtig-link {
    padding: 0 20vw;
    background-color: rgb(80, 80, 80);
}

    @media (max-width: 600px), (orientation: portrait) {
        .qtig-link {
            padding: 0;
        }
    }

    .button-container {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        background-color: #3A4748;
        border: none;
    }

    .big-button {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 80%;
        color: white;
        font-size: calc(13px + 1vmax);
        font-weight: 200;
        background-color: transparent;
        padding: 0;
        border: none;
        transition: opacity 0.5s ease-in-out;
        transition: transform 0.5s ease;
        transform: scale(1);
        opacity: 1;
    }

        .big-button:hover {
            opacity: 0.95;
            transform: scale(0.99);
        }

    .big-footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-height: 15vh;
        margin: 0;
        padding: 8vh 2vw 4vh 2vw;
        background-color: rgb(32, 32, 32);
    }

    .footer-cols {
        width: 30vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 1vw;
    }

    .tw-logo-footer {
        height: calc(10px + 10vmin);
        float: left;
        pointer-events: none;
        margin: 1vh 0px 1vh 0;
    }

    .footer-links-text {
        color: var(--backColor);
        font-size: 1em;
        font-weight: 200;
        text-align: center;
        line-height: 2;
        text-decoration: underline;
        transition: transform 0.3s; /* Smooth transitions */
    }
        .footer-links-text:hover {
            color: var(--orange);
            transform: scale(1.05); /* Slightly scales the icon on hover */
        }

    .footer-tw-text {
        color: var(--backColor);
        font-weight: 200;
        text-align: center;
        font-size: 0.75em;
        line-height: 1;
    }

    .footer-icons {
        display: flex;
        align-items: center;
    }

    .social-icons {
        padding: 0 2vw;
    }
    /***********  Qtig Homepage ***********/

    .qtig-Body {
        background-color: var(--backColor);
        margin: 0;
        padding: 6vh 0 0 0;
    }

    .prova {
        color: white;
        font-weight: 300;
        text-align: center;
        font-size: 3em;
    }
    /***********  Privacy ***********/
    .Privacy-Body {
        background-color: var(--backColor);
        margin: 0;
        padding: 6vh 0 0 0;
    }

    .PrivacyDoc {
        background-color: var(--backColor);
        margin: 0;
        padding: 30px;
        font-size: calc(10px + 0.9vmax);
        color: #101010;
        line-height: 1.2;
        font-weight: 200;
        text-align: left;
        user-select: none;
    }

    .Tw-textPrivacy {
        display: flex;
        flex-direction: column;
        height: 100%;
        font-size: calc(10px + 1vmax);
        color: #202020;
        line-height: 1.1;
        margin: 0;
        padding: 20px;
        font-weight: 300;
        text-align: center;
        align-items: center;
        justify-content: center;
    }

    h3 {
        font-size: calc(12px + 1vmax);
        font-weight: 600;
    }

    .AboutLogo {
        display: flex;
        height: 100%;
        margin: 0;
        padding: 1vh 0 0 0;
        align-items: center;
        justify-content: center;
        background-color: black;
    }
    /***********  cookie-banner ***********/
    .cookie-banner {
        z-index: 1000;
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: var(--orange);
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 15px 20px 30px 20px;
    }

        .cookie-banner p {
            margin-bottom: 20px;
            max-width: 100%;
            color: #fff; /* white text */
            font-size: 0.95em;
            font-weight: 300;
            line-height: 1.3;
        }

        .cookie-banner div {
            display: flex;
            flex-direction: row;
            align-items: center;
        }

        .cookie-banner button {
            font-size: 0.8em;
            margin: 0 20px;
            padding: 5px;
            width: 100px;
            background-color: #fff;
            border: 0;
            color: #444;
            border-radius: 1px;
        }

    .orange-rect {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0vh 0vw 0vh 0vw;
        padding: 0;
        background-color: var(--orange);
    }

        .orange-rect.transp {
            background-color: transparent;
        }

    .News-text {
        text-align: center;
        align-items: center;
        font-size: calc(14px + 1.1vmax);
        color: var(--backColor);
        line-height: 1.1;
        margin: 0;
        padding: 15px 20px;
        font-weight: 300;
        user-select: none;
    }


    /** Lines Wrapper  **/
    .lines-wrapper {
        z-index: 50;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .line {
        width: 100%;
        background-color: black;
    }

    .line1 {
        height: 1px;
        margin-bottom: 9px;
    }

    .line2 {
        height: 2px;
        margin-bottom: 8px;
    }

    .line3 {
        height: 3px;
        margin-bottom: 7px;
    }

    .line4 {
        height: 4px;
        margin-bottom: 6px;
    }

    .line5 {
        height: 5px;
        margin-bottom: 5px;
    }

    .line6 {
        height: 6px;
        margin-bottom: 4px;
    }

    .line7 {
        height: 8px;
        margin-bottom: 3px;
    }

    .line8 {
        height: 10px;
        margin-bottom: 2px;
    }

    .line9 {
        height: 16px;
        margin-bottom: 1px;
    }

    .line10 {
        height: 20px;
        margin-bottom: 0px;
    }


