/* tables.css */

table {
    width: 100%;
    border-collapse: collapse;
}

th,
td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ccc;
}




/**************** TABLES  ********************/
.summaryTable {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    background-color: #fff;
    color: #202020;
    padding: 0 0 5vh 0;
    font-size: calc(11px + 0.2vw);
    user-select: none;
}

    .summaryTable tbody {
        width: 100%;
        table-layout: fixed;
        border-collapse: collapse;
    }

    .summaryTable table td:nth-child(1) {
        width: 30%;
        text-align: left;
    }
    .summaryTable table td:nth-child(2) {
        width: 70%;
        text-align: left;
    }


    .summaryTable td {
        text-align: left;
        color: #3C3C3C;
        word-break: break-word;
    }

    .summaryTable th {
        font-weight: 400;
        font-size: 1.3em;
        text-align: center;
        color: #fff;
        background-color: var(--grey);
    }
