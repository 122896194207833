/* Hide the default radio button */
.custom-radio input[type="radio"] {
    position: absolute;
    z-index: 0;
    opacity: 0;
    cursor: pointer;
}

/* Create a custom radio button */
.radio-circle {
    position: relative;
    z-index: 0;
    display: inline-block;
    margin-right: 10px;
    width: 15px;
    height: 15px;
    background-color: #eee;
    border: 2px solid #ccc;
    border-radius: 50%;
    cursor: pointer;
}

/* Style the custom radio button when checked */
.custom-radio input[type="radio"]:checked ~ .radio-circle {
    background-color: white;
    border: 3px solid #ccc;
}

    /* Add a checkmark inside the custom radio button when checked */
    .custom-radio input[type="radio"]:checked ~ .radio-circle::after {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 10px;
        height: 10px;
        background-color: var(--orange);
        border-radius: 50%;
    }

/* Custom checkbox button */
input[type=checkbox] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    /* create custom checkbox appearance */
    display: inline-block;
    width: 18px;
    height: 18px;
    padding: 2px;
    /* background-color only for content */
    background-clip: content-box;
    border: 1.5px solid #bbbbbb;
    border-radius: 10px;
    background-color: #eee;
    margin: 0 15px;
    cursor: pointer;
    &:checked
        {
            background-color: var(--orange);
        }
    &:focus 
        {
        outline: none !important;
        }
}

.checkBox {
    display: flex;
    align-items: center;
    padding: 8px;
    font-size: calc(7px + 1vmin);
    margin: 0px;
    background-color: transparent;
    border-bottom: 1px solid #ccc;
}
.label {
    display:flex;
    align-items: center;
}
.inputNumber {
    max-width: 5em;
}
.inputText {
    margin-left: 10px;
}