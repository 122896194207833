/************* Format for the Menu Bar **********/
.menuline {
    display: flex;
    margin: 0px;
    justify-content: space-between;
}

.menubars {
    width: 25vw;
    border: 0px solid white;
    padding: 8px 0 5px 0;
    margin: 0;
    background-color: var(--grey);
    color: white;
    font-size: calc(10px + 0.9vmin);
    text-decoration: none;
    text-align: center;
    font-weight: 400;
    user-select: none;
}
    .menubars:hover {
        color: white;
        font-weight: bold;
        background-color: #ccc;
    }
    .menubars:active {
        color: white;
        font-weight: bold;
        background-color: var(--orange);
    }
.menuActive {
    width: 25vw;
    border: 0px solid white;
    padding: 8px 0 5px 0;
    margin: 0;
    background-color: var(--orange);
    color: white;
    font-size: calc(10px + 0.9vmin);
    text-decoration: none;
    text-align: center;
    font-weight: 400;
    user-select: none;
}
    .menuActive:hover {
        color: white;
        font-weight: bold;
        background-color: var(--orange);
    }

    .menuActive:active {
        color: white;
        font-weight: bold;
        background-color: var(--orange);
    }


.menu-icon {
    height: 18px;
    width: 18px;
    margin: 3px 10px 0 0;
}
.send-icon {
    height: 22px;
    width: 22px;
    margin: 0;
}
