/* tables.css */

table {
    width: 100%;
    border-collapse: collapse;
}

th,
td {
    padding: 8px;
}

th {
    background-color: transparent;
    font-weight: bold;
}



/**************** TABLES  ********************/
.PdocTable {
    display: flex;
    flex-wrap: wrap;
    margin: 0 20% 0px 20%;
    padding: 15px 20px 35px 20px;
    font-size: calc(13px + 0.2vh);
    user-select: none;
    border-radius: 8px;
    background-color: #ffff;
    min-height: 20vh;
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.1);
}
@media (max-width: 801px) {
    .PdocTable {
        margin: 0 3% 0 3%;
    }
}

        .PdocTable tbody {
            width: 100%;
            table-layout: fixed;
            border-collapse: collapse;
            border: none;
        }

        .PdocTable table td:nth-child(1),
        .PdocTable table th:nth-child(1) {
            width: 12%;
            text-align: center;
            padding-left:4%;
        }
        .PdocTable table td:nth-child(2) {
            width: 65%;
            text-align: left !important;
            padding-left: 8px;
        }

        .PdocTable table th:nth-child(2) {
            width: 65%;
            text-align: center;
            padding-left: 8px;
        }

        .PdocTable table td:nth-child(3),
        .PdocTable table th:nth-child(3) {
            width: 10%;
            text-align: center;
            padding-left: 0;
        }
        .PdocTable table td:nth-child(4),
        .PdocTable table th:nth-child(4) {
            text-align: center;
            width: 13%;
            padding-left: 0;
        }

        .PdocTable td {
            width: 100%;
            height: calc(30px + 1.25vh);
            text-align: left;
            padding-right: 0;
            padding-bottom: 0;
            padding-top: 0;
            word-break: break-word;
            border: none;
        }

        .PdocTable th {
            font-weight: 600;
            font-size: 1em;
            color: #606060;
            border-bottom: 1px solid var(--grey);
        }
        .PdocTable tr {
            background-color: transparent;
            border-top: none;
            border-bottom: 1px solid var(--backColor);
        }

    .PdocTable tbody tr:nth-child(even) {
        background-color: #ffffff ;
    }
    .PdocTable tbody tr:nth-child(odd) {
        background-color: #ffffff;
    }

    .PdocTable tbody tr:hover {
        background-color: #ffffff;
    }

.td.selected-project {
    font-weight: 700;
}
.title-project {
    font-weight: 300;
    color: #3C3C3C;
    padding: 0 2em;
    margin: 0;
    font-size: calc(12px + 1vmin);
}

/**************** TABLES  ********************/
.PdocTableD {
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 10px 0;
    padding: 0;
    font-size: calc(9px + 0.6vw);
    user-select: none;
    border-radius: 2px;
    user-select: none;
}

    .PdocTableD tbody {
        width: 100%;
        table-layout: fixed;
        border-collapse: collapse;
    }

    .PdocTableD table td:nth-child(1) {
        width: 15%;
        text-align: center;
    }

    .PdocTableD table td:nth-child(2) {
        width: 70%;
        text-align: left !important;
    }

    .PdocTableD table td:nth-child(3) {
        width: 15%;
        text-align: center;
    }

    .PdocTableD td {
        text-align: left;
        color: #3C3C3C;
        padding: 1.5vh 1vw;
        word-break: break-word;
        font-size: 1em;
    }

    .PdocTableD th {
        font-weight: 300;
        font-size: 1.1em;
        text-align: center;
        color: #3c3c3c;
        background-color: rgba(0, 0, 0, 0.1);
    }

    .PdocTableD tr {
        background-color: #fff;
        border-bottom: 1px solid #ddd;
    }
        .PdocTableD tbody tr:hover {
            background-color: #eee;
        }


/* ***************************************** */

/**************** TABLES Contracts PDFs  ********************/
.PdocTablePDF {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    font-size: calc(9px + 0.6vw);
    user-select: none;
    border-radius: 2px;
    user-select: none;
}

    .PdocTablePDF tbody {
        width: 100%;
        table-layout: fixed;
        border-collapse: collapse;
    }

    .PdocTablePDF table td:nth-child(1) {
        width: 15%;
        text-align: center;
        padding-right: 7%;
    }
    .PdocTablePDF table th:nth-child(1) {
        width: 15%;
        text-align: center;
    }
    .PdocTablePDF table td:nth-child(2) {
        width: 10%;
        text-align: center;
    }
    .PdocTablePDF table th:nth-child(2) {
        width: 10%;
        text-align: left;
    }
    .PdocTablePDF table td:nth-child(3) {
        width: 60%;
        text-align: left;
        padding-left: 8px;
    }
    .PdocTablePDF table th:nth-child(3) {
        width: 60%;
        text-align: center;
    }
    .PdocTablePDF table td:nth-child(4) {
        width: 15%;
        text-align: center;
    }
    .PdocTablePDF table th:nth-child(4) {
        width: 15%;
        text-align: center;
    }


    .PdocTablePDF td {
        text-align: left;
        color: #3C3C3C;
        padding: 1.5vh 1vw;
        word-break: break-word;
        font-size: 1em;
    }

    .PdocTablePDF tr {
        background-color: #fff;
        border-bottom: 1px solid #ddd;
    }

    .PdocTablePDF tbody tr:hover {
        background-color: #eee;
    }

/**************** TABLES Analysis ********************/
.TopicsTable {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    font-size: calc(11px + 0.5vmin);
    user-select: none;
    border-radius: 2px;
    user-select: none;
}

    .TopicsTable tbody {
        width: 100%;
        table-layout: fixed;
        border-collapse: collapse;
    }
    .TopicsTable table th:nth-child(4) {
        padding-left: 15px;
    }
    .TopicsTable table td:nth-child(1) {
        width: 15%;
        text-align: center;
        padding-left: 2px;
        padding-right: 2px;
    }
    .TopicsTable table td:nth-child(2) {
        width: 8%;
        text-align: center;
        padding-left: 0;
        padding-right: 0;
        color: var(--grey);
    }
    .TopicsTable table td:nth-child(3) {
        width: 67%;
        text-align: left;
    }

    .TopicsTable table td:nth-child(4)
    {
        width: 10%;
        text-align: center;
    }


    .TopicsTable td {
        color: #3C3C3C;
        padding: 2% 3%;
        word-break: break-word;
    }

    .TopicsTable tr {
        background-color: #fff;
        border-bottom: 1px solid #ddd;
    }


/********** GroupTable *************/

.GroupTable {
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 20px 30px;
    padding: 0;
    font-size: calc(11px + 0.5vmin);
    user-select: none;
    border-radius: 2px;
    user-select: none;
}

    .GroupTable tbody {
        width: 100%;
        table-layout: fixed;
        border-collapse: collapse;
    }

    .GroupTable table td:nth-child(1) {
        width: 15%;
        text-align: center;
        padding-left: 2px;
        padding-right: 2px;
    }

    .GroupTable table td:nth-child(2) {
        width: 65%;
        text-align: left;
        padding: 10px 0;
    }

    .GroupTable table td:nth-child(3) {
        width: 20%;
        text-align: left;
    }

    .GroupTable td {
        color: #3C3C3C;
        padding: 2% 3%;
        word-break: break-word;
    }

    .GroupTable tr {
        background-color: #fff;
        border-bottom: 1px solid #ddd;
    }
/* ***************************************** */
/* pagination.css */
.paginationContainer {
    width: 100%;
    max-width: 100vw;
    display: flex;
    justify-content: center;
    padding: 0;
    margin: 0;
}
.pagination {
    max-width: 100vw;
    display: flex;
    list-style: none;
    justify-content: center;
    padding: 20px 0 0 0;
}

    .pagination li {
        margin: 0 4px;
    }

        .pagination li a {
            display: inline-block;
            padding: 6px 12px;
            background-color: #f5f5f5;
            border: 1px solid #ddd;
            color: #333;
            text-decoration: none;
        }

            .pagination li a:hover,
            .pagination li.active a {
                background-color: #3C3C3C;
                color: #fff;
            }

        .pagination li.disabled a {
            color: #999;
            pointer-events: none;
        }
/******    Annotation tool *****/
.context-text {
    text-align: left;
    margin: 0;
    padding: 2vh 1.5vw 1.5vh 3vw;
    font-size: calc(10px + 0.5vw);
    background-color: #F5F5F5;
}

.highlighted-text {
    color: #3C3C3C;
    background-color: #f9dfb5;
}
.nonHighlighted-text {
    color: #3C3C3C;
    background-color: rgba(240, 110, 30, 0.5);
}
.context-text::selection {
    color: #3C3C3C;
    background-color: rgba(240, 110, 30, 0.5);
}
/******    Editing *****/
.iconButton {
    justify-content: center;
    align-items: center;
    color: white;
    background-color: transparent;
    border: none;
    padding: 0;
    margin: 0 7px;
}

.text_small {
    font-size: calc(8px + 1vmin);
    color: #3C3C3C;
    font-weight: normal;
    font-family: Montserrat;
}
.text_small2 {
    font-size: calc(8px + 0.9vmin);
    color: var(--orange);
    font-weight: normal;
    font-family: Montserrat;
}
.items-per-page-container {
    display: inline-flex;
    align-items: center;
    margin-right: 3vw;
    color: var(--orange);
}

.items-per-page-input {
    width: 2.5rem;
    margin-left: 0.5rem;
    padding-left: 0.2rem;
    color: var(--orange);
    font-weight: 600;
    border-bottom: 1px solid #ddd;
    border-right: 1px solid #ddd;
    border-top: 1px solid #ddd;
    border-left: 1px solid #ddd;
}

/*********  Q&A *********/


.input-format {
    display: grid;
    padding: 1vw;
    font-size: 0.9em;
    line-height: 1em;
    border: 1px solid #DEE1E2;
    border-radius: 2px;
    color: #3C3C3C;
}
.inputTextBox {
    padding: 7px;
    font-size: calc(7px + 0.7vw);
}
.inputTextBox1 {
    max-width: 4.2em;
    padding: 7px;
    font-size: calc(7px + 0.7vw);
}

.leftBar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 1vw;
    padding: 2vw;
    background-color: #fff;
}
/*********  PDF *********/
.pdf_titles {
    width: 93%;
    text-align: left;
    background-color: transparent;
    padding: 0 0.5em 0 0;
    font-family: Montserrat;
    font-size: calc(10px + 0.8vmin);
    color: #333;
    margin-top: 9px;
    border-bottom: 1px solid #f2f2f2;
}
.inputTextBox2 {
    padding: 4px 8px;
    font-size: calc(12px + 0.3vw);
    margin-left: 10px;
}

.inputTextBox3 {
    margin-left: 10px;
    padding: 4px 5px;
    font-size: calc(7px + 0.7vw);
    max-width: 5em;
}
.icon-button {
    background-color: transparent;
    border: none;
    padding: 0;
    margin: 0;
}
    .icon-button:focus {
        outline: none;
    }
.pdf-button {
    background-color: transparent;
    color:rgba(0,0,0,0.6);
    border: none;
    padding: 0;
    margin: 0;
    text-align: left;
    text-indent: -22px;
}
    .pdf-button:focus {
        outline: none;
    }
    .pdf-button:hover {
        color: var(--orange);
    }
.delete-pdf-button {
    background-color: transparent;
    color: rgb(0,0,0,0.2);
    border: none;
    cursor: pointer;
    padding: 0;
    margin: 0;
    transition: width 0.3s, height 0.3s, color 0.3s; /* Smooth transition for size change */
}

    .delete-pdf-button:hover {
        width: 1.2em; /* Width on hover */
        height: 1.2em; /* Height on hover */
        color: var(--orange);
    }
