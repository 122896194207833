body {
    background-color: #202020;
}

.form_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #E3E3E3;
    padding: 20px 0px;
    height: 100%;
    width: 100%;
}

.action_btn {
    padding: 10px;
    font-size: calc(7px + 1vmin);
    font-family: Montserrat;
    margin: 10px 0;
    border: none;
    color: white;
    background-color: var(--orange);
    cursor: pointer;
    border-radius: 5px;
    box-shadow: 3px 3px 3px 0px #BBB;
}

.action_btn_google {
    padding: 10px;
    font-size: calc(7px + 1vmin);
    font-family: Montserrat;
    margin-bottom: 10px;
    border: none;
    color: white;
    background-color: #1C81B8;
    margin-top: 10px;
    cursor: pointer;
    border-radius: 5px;
    box-shadow: 3px 3px 3px 0px #BBB;
}

.action_btn_disabled {
    background-color: var(--grey);
    pointer-events: none;
    box-shadow: none;
}

.action-btn-container {
    display: flex;
    justify-content: space-between;
    padding: 0;
    margin: 1vh 0 0 0;
}
.form_container1 {
    display: flex;
    flex-direction: column;
    background-color: #DEE1E2;
    /*box-shadow: 5px 5px 5px 0px #BBB;*/
    padding: 30px 20px 20px 20px;
    margin-top: 2vh;
    text-align: center;
}
/************* LOGIN format **********/

.login {
    height:75vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login__textBox {
    padding: 10px;
    font-size: calc(7px + 1vmin);
    font-family: Montserrat;
    margin-bottom: 15px;
}
.login__text {
    padding: 5px;
    font-size: calc(7px + 1vmin);
    line-height:2.5;
    font-family: Montserrat;
    margin-bottom: 7px;
}

/****************Google #4285f4 ************************/
.google {
    background-color: #1C81B8;
    cursor: pointer;
    margin-top: 10px;
}

.login div {
    margin-top: 5px;
}

/************* REGISTER format **********/
.register {
    height: 75vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
}


.register__textBox {
    padding: 10px;
    font-size: calc(7px + 1vmin);
    font-family: Montserrat;
    margin: 0px 0px 15px 0px;
}

.register div {
    margin-top: 5px;
}

/************* RESET format **********/
.reset {
    margin-top: 15vh;
    height: 40vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

.reset__textBox {
    padding: 10px;
    font-size: calc(7px + 1vmin);
    font-family: Montserrat;
    margin: 10px 10px 20px 10px;
}

.reset div {
    margin-top: 5px;
}

/************* DASHBOARD format **********/
.dashboard {
    height: 5vh;
    width: 100vw;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}

.dashboard__text {
    display: flex;
    align-items: center;
    margin-right: 20px;
    margin-bottom: 5px;
    font-size: calc(5px + 1vmin);
    font-family: 'Arial';
    color: var(--backColor);
}
.dashboard__text div {
        margin-right: 10px;
    }

.exit__btn {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    margin: 0;
}
    .exit__btn > * {
        transition: transform 0.2s, opacity 0.2s; /* Smooth transitions */
    }
    /* Hover state */
    .exit__btn > *:hover {
        color: var(--orange);
        transform: scale(1.1); /* Slightly scales the icon on hover */
    }
.exit__btn .d_exit {
        display: block;
        height: 20px
    }

.exit__btn .h_exit {
    display: none;
    height: 20px
}

.exit__btn:hover .d_exit {
    display: none;
    height: 20px
}

.exit__btn:hover .h_exit {
    display: block;
    height: 20px
}
.exit-icon {
    max-width: 4vw;
    font-size: calc(18px + 0.4vw);
    color: var(--backColor);
    cursor: pointer;
}

.user__btn {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0 20px 0 0;
    margin: 0;
}

    .user__btn > * {
        transition: transform 0.2s, opacity 0.2s; /* Smooth transitions */
    }
        /* Hover state */
        .user__btn > *:hover {
            color: var(--orange);
            transform: scale(1.1); /* Slightly scales the icon on hover */
        }

